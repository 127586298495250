import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify"; // Importing toastify module
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button, Form, Pagination } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css"; // Import toastify css file
import "./uploadexcel.css";
import useFetch from "../Hooks/useFetch";
import { API_BASE_URL } from "../utils/constants";
import { HiMiniArrowLeftCircle } from "react-icons/hi2";
import LoadingSpinner from "./LoadingSpinner";
import { headers } from "../utils/headers";
import ClearForm from "./ClearForm";
import ReusableTable from "./ReusableTable";

// For date validation we use this
const validateDate = (dateString) => {
  // Check if dateString is a string
  if (typeof dateString !== "string") {
    return false;
  }

  // Regular expression to match dd-mm-yyyy format
  const regex = /^\d{2}-\d{2}-\d{4}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split("-").map(Number);

  // Check if day, month, and year are valid numbers
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  // Create a new Date object
  const date = new Date(year, month - 1, day);

  // Validate the Date object
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    return false;
  }

  return true;
};

// for text only we use regex in this and excluding ampersand &
const isTextOnly = (text) => {
  const textOnlyPattern = /^[A-Za-z\s&.]+$/;
  return textOnlyPattern.test(text);
};

const UploadExcel = () => {
  // function UploadExcel() {
  const [errorExcelBlob, setErrorExcelBlob] = useState(null);
  const [errorCount, setErrorCount] = useState(0);
  const [excelData, setExcelData] = useState([]); // To store the validated Excel data
  const [data, setData] = useState([]); // To store the validated Excel data
  const [headers1, setHeaders1] = useState([]); // To store the validated Excel data
  const [selectedClient, setSelectedClient] = useState([]); //the clients Data to populate dropdown
  const [selectedProduct, setSelectedProduct] = useState([]); //to add the product from the dropdown
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [validationMessage, setValidationMessage] = useState(false); // State for validation message
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null); // Ref for file input
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Set to 10 records per page
  // const [totalPages, setTotalPages] = useState(0);
  const [clearForm, setClearForm] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [goBackToSelect, setGoBackToSelect] = useState(false);

  // State to track the progress
  const [progress, setProgress] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  // To fetch Clients Data
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Client`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedClient = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        // console.log(parsedClient);
        setSelectedClient(parsedClient);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchClient();
  }, []);

  // console.log(clientsData);
  // console.log(selectedClient);

  // API Call for Product data
  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/products?client_id=${selectedClientID}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const products = Array.isArray(result) ? result : JSON.parse(result); // Ensure parsedArbitrators is an array
        // console.log(products);
        setSelectedProduct(products); // Set products data
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedClientID]);

  // // console.log(selectedProduct);

  const handleSelectChange = (e) => {
    const selectedID = e.target.value;
    setSelectedClientID(selectedID);
  };

  const handleProductChange = (e) => {
    const selectedID = e.target.value;
    setSelectedProductID(selectedID);
  };

  // to check the headers in the aboject are exactly matching with the imported excel
  const validateHeaders = (headers, excelHeaders) => {
    if (!excelHeaders || excelHeaders.length === 0) return false;
    if (excelHeaders.length !== headers.length) return false;

    for (let i = 0; i < excelHeaders.length; i++) {
      if (excelHeaders[i] !== headers[i].name) {
        return false;
      }
    }

    return true;
  };

  //  Here we validate the data

  const validateData = (headers, data) => {
    const errors = [];

    if (!data || data.length === 0) {
      console.error("No data available for validation.");
      return errors;
    }

    for (let i = 1; i < data.length; i++) {
      //to skip headers
      const row = data[i];
      if (!row) continue;

      headers.forEach((header, index) => {
        let cellValue = row[index];

        // Ensure empty cells are represented as empty strings
        if (cellValue === undefined || cellValue === null) {
          cellValue = "";
        }

        if (!header || !header.validations) {
          // console.error("Invalid header or validations:", header);
          return;
        }

        header.validations.forEach((validation) => {
          switch (validation.type) {
            case "notEmpty":
              if (
                !cellValue ||
                (typeof cellValue === "string" && cellValue.trim() === "")
              ) {
                errors.push({
                  row: i + 1,
                  column: index + 1,
                  message: `${header.name} cannot be empty`,
                });
              }
              break;
            case "integer":
              if (cellValue && !Number.isInteger(Number(cellValue))) {
                errors.push({
                  row: i + 1,
                  column: index + 1,
                  message: `${header.name} must be an integer`,
                });
              }
              break;
            case "date":
              if (cellValue && !validateDate(cellValue)) {
                errors.push({
                  row: i + 1,
                  column: index + 1,
                  message: `${header.name} must be a valid date`,
                });
              }
              break;
            case "textOnly":
              if (cellValue && !isTextOnly(cellValue)) {
                errors.push({
                  row: i + 1,
                  column: index + 1,
                  message: `${header.name} must contain only text, spaces, and ampersands`,
                });
              }
              break;
            default:
              break;
          }
        });
      });
    }

    return errors;
  };

  const generateErrorExcelFile = (errors) => {
    const errorData = [
      ["Row Number", "Column Number", "Error Message"],
      ...errors.map((error) => [error.row, error.column, error.message]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(errorData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Error Report");

    const errorBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    return new Blob([errorBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };

  //   utility function to remove non-printable characters
  const removeNonPrintableChars = (str) => {
    if (typeof str !== "string") return str;
    return str.replace(/[\x00-\x1F\x7F]/g, "");
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    // return `${day}/${month}/${year}`;
    return `${month}/${day}/${year}`;
  };

  //This is  File upload Code
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(e.target.files[0]);
    if (!file) {
      alert("Please upload an Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Get headers from the first row assuming it contains headers
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      const extractedHeaders = sheetData[0];
      const rowData = sheetData.slice(1);
      setHeaders1(extractedHeaders);
      setData(rowData);

      const isHeaderValid = validateHeaders(headers, extractedHeaders);
      if (!isHeaderValid) {
        alert(
          "Excel format is not matching. Please upload the Excel in correct format."
        );
        return;
      }

      const errors = validateData(headers, sheetData);
      if (errors.length > 0) {
        const errorExcelBlob = generateErrorExcelFile(errors);
        setErrorExcelBlob(errorExcelBlob);
        setErrorCount(errors.length);
      } else {
        setValidationMessage(true);
        setErrorCount(0);

        // call function to show Data
        handleShowData();

        // call function to show Data

        // Convert sheet data to an object format for easier manipulation
        const convertedData = sheetData.map((row, index) => {
          if (index === 0) return row; // Skip header row

          const rowData = {}; // Create a new object for each row

          extractedHeaders.forEach((header, i) => {
            let value = row[i];

            // Ensure empty cells are represented as empty strings
            if (value === undefined || value === null) {
              value = "";
            }

            // Remove special characters from the data
            if (typeof value === "string") {
              value = value.replace(/\u001e/g, ""); // Remove specific special character
            }

            // Convert and format date values
            if (header.toLowerCase().includes("date") && !isNaN(value)) {
              const dateValue = new Date((value - 25569) * 86400 * 1000); // Convert Excel serial date
              value = formatDate(dateValue); // Format to "DD/MM/YYYY"
            }

            rowData[header] = value; // Assign the processed value to the rowData object
          });

          return rowData; // Return the processed row
        });

        // Filter out any empty objects
        const cleanedData = convertedData.filter(
          (row) => Object.keys(row).length > 0
        );
        setExcelData(cleanedData.slice(1)); // Store validated data for transfer
        // setTotalPages(Math.ceil(cleanedData.length / itemsPerPage));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // console.log(excelData);
  // console.log(data);
  // console.log(headers1);

  const isFormValid = () => {
    return selectedClient && selectedProduct && selectedFile;
  };

  const handleShowData = () => {
    if (isFormValid()) {
      setShowTable(true);
    } else {
      // alert('Please fill all the fields.');
    }
  };

  const downloadErrorExcel = () => {
    if (errorExcelBlob) {
      saveAs(errorExcelBlob, "error_file.xlsx");
      window.location.reload();
    } else {
      alert("No error file available for download.");
    }
  };

  const handleTransfer = async () => {
    // Add the selected product to each row
    const updatedData = excelData.map((row) => ({
      Client_id: selectedClientID,
      User: "admin",
      Product_id: selectedProductID,
      ...row,
    }));

    setLoading(true); // Start loading indicator
    setTotalRecords(updatedData.length); // Set total records for progress bar
    setProgress(0); // Initialize progress to 0

    try {
      // Map through each row and upload data sequentially
      await updatedData.reduce(async (previousPromise, row, index) => {
        // Wait for the previous promise to resolve
        await previousPromise;

        console.log(`Uploading record ${index + 1} of ${updatedData.length}`);

        // Make the API call for the current row
        const response = await fetch(`${API_BASE_URL}/api/UploadData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case: [row] }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
          );
        }

        // Process the response
        await response.json();

        // Update the progress after each upload
        setProgress((prevProgress) => prevProgress + 1);

        // Log progress to the console
        console.log(
          `Progress: ${index + 1}/${updatedData.length} records uploaded`
        );

        // Optional: Small delay to show progress smoothly
        await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if needed
      }, Promise.resolve()); // Start with an immediately resolving promise

      // Show success toast message
      toast.success("Data Uploaded Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 1000,
      });

      // Reset form and inputs
      setClearForm(true);
      setSelectedProductID(0);
      setSelectedClientID(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear file input
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false); // Stop loading indicator when all tasks complete
    }
  };


  const handleGoBack = () => {
    alert("function Called");
    setGoBackToSelect(true);
  };


  // For Pagination

  // Calculate the total number of pages
  const totalPages = Math.ceil(excelData.length / rowsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Calculate the starting and ending index of the rows for the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = excelData.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  // Pagination logic for showing only 5 buttons at a time
  const maxPagesToShow = 5;
  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxPagesToShow / 2),
      totalPages - maxPagesToShow + 1
    )
  );
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  const displayedPages = pageNumbers.slice(startPage - 1, endPage);

  // Handlers for pagination
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // For Pagination

  return (
    <div className="">
      {!clearForm && (
        <div className="row align-items-center">
          {/* <div className="col-md-1">
            {showTable && (
              <HiMiniArrowLeftCircle
                className="customIcon "
                onClick={handleGoBack}
              />
            )}
          </div> */}
          <div className="col-md-4 clip-path">
            {/* {!validationMessage && ( */}
            <p className=" pt-3 fs-4 ms-2 fw-bold ">
              {validationMessage ? "Upload Excel File" : "Select Excel File"}
            </p>
            {/* )} */}
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2">
            {/* {loading && <p>{progressMessage}</p>} */}
            {/* Display progress message */}
            {validationMessage && (
              <button className="custBtn mt-3" onClick={handleTransfer}>
                Upload
              </button>
            )}
          </div>
        </div>
      )}

      {loading && (
        <div className="progress-container">
          <p className="progress-text">
            {progress}/{totalRecords} records uploaded
          </p>
          <div className="progress-bar-background mt-3">
            <div
              className="progress-bar"
              style={{
                width: `${(progress / totalRecords) * 100}%`,
              }}
            >
              {Math.round((progress / totalRecords) * 100)}%
            </div>
          </div>
        </div>
      )}

      {!clearForm && !validationMessage && (
        <div className="row justify-content-center align-items-center">
          <div className="col-md-4 mb-3">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSelectChange}
              className="custom_input"
              required
            >
              <option value="" disabled selected>
                Choose a Bank
              </option>
              {selectedClient.map((item) => (
                <option key={item.Client_id} value={item.Client_id}>
                  {item.client_name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-4 mb-3">
            <Form.Select
              aria-label="Default select example"
              onChange={handleProductChange}
              className="custom_input"
              required
            >
              <option value="" disabled selected>
                Choose a Product
              </option>
              {selectedProduct.map((item) => (
                <option key={item.Product_id} value={item.Product_id}>
                  {item.Product_name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-4 mb-3">
            <Form.Group className="">
              <Form.Control
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                className="custom_input"
                ref={fileInputRef}
              />
            </Form.Group>
          </div>

          {loading && (
            <div>
              {console.log("Re-rendering progress bar", progress, totalRecords)}
              <p>
                {progress}/{totalRecords} records uploaded
              </p>
              <progress value={progress} max={totalRecords}></progress>
            </div>
          )}

          {/* <div className="col-md-3 mb-4 d-flex justify-content-center">
            {!showTable && validationMessage && (
              <button
                className="custBtn"
                onClick={handleShowData}
                disabled={!isFormValid()} // Disable button until form is valid
              >
                Show Data
              </button>
            )}
          </div> */}

          {/* <Table /> */}
        </div>
      )}

      {/* To show error count */}
      <div className="">
        {errorCount > 0 && (
          <div className="row">
            <div className="col-md-4">
              <p className="text-danger text-center fw-bold">
                Total Errors: {errorCount}
              </p>
            </div>
            <div className="col-md-4">
              <p className="text-danger ms-3">
                To get more details and download excel
              </p>
            </div>
            <div className="col-md-4">
              <a
                href="#"
                onClick={downloadErrorExcel}
                disabled={!errorExcelBlob}
                className="ps-5 custom_anchor text-danger fw-bold"
              >
                Download Error Excel Here
              </a>
            </div>
          </div>
        )}
      </div>

      {/* {!clearForm && showTable && ( */}
      {!clearForm && validationMessage && !loading && (
        <ReusableTable
          data={currentRows}
          currentPage={currentPage}
          pageNumbers={pageNumbers}
          setCurrentPage={setCurrentPage}
        />
      )}

      {clearForm && (
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center mt-3">
            <ClearForm
              message="File Uploaded Successfully!!!!"
              redirectPath="/instdashboard"
            />
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default UploadExcel;
