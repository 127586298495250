import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Pagination, Form, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify"; // Importing toastify module
import "react-toastify/dist/ReactToastify.css"; // Import toastify css file
import useFetch from "../Hooks/useFetch";
import { FaCheck } from "react-icons/fa";
import { API_BASE_URL } from "../utils/constants";
import Refresh from "./Refresh";
import ReusableTable from "./ReusableTable";
import LoadingSpinner from "./LoadingSpinner";
import ClearForm from "./ClearForm";
import ReusableTableWithFilter from "./ReusableTableWithFilter";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // FontAwesome icons
import("./components.css");

const AssignArbitrator = () => {
  const [data, setData] = useState([]);
  const [arbitrators, setArbitrators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [error, setError] = useState(null);
  const [unassignedLots, setUnassignedLots] = useState([]); // Track which data to show
  const [showUnassigned, setShowUnassigned] = useState(false); // Track which data to show
  const [showAssigned, setShowAssigned] = useState(false); // Track which data to show
  const [getData, setGetData] = useState(false); // Track which data to show
  const [unassignedID, setUnassignedID] = useState(null); // Track which data to show
  const [showTable, setShowTable] = useState(false); // Track whether to show table
  const [currentPage, setCurrentPage] = useState(1); // for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10); // for pagination
  const [assignedData, setAssignedData] = useState([]); // State for assigned data
  const [unassignedData, setUnassignedData] = useState([]);
  const [clearForm, setClearForm] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedLotId, setSelectedLotId] = useState(null); // Track selected lot
  const [currentLotId, setCurrentLotId] = useState(null); // Track the currently selected lot
  const [isDataFetched, setIsDataFetched] = useState(false); // Track if data has been fetched
  const [assignedLots, setAssignedLots] = useState(new Set()); // Track assigned lots
  // const [selectedArbitrator, setSelectedArbitrator] = useState(null);
  const [filteredDataForSelect, setFilteredDataForSelect] = useState(data);
  const [isPending, setIspending] = useState(false);
  const [showData, setShowData] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedArbitrator, setSelectedArbitrator] = useState("all");
  const [headers, setHeaders] = useState([]);

  // for unassigned Data
  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage1 = 10;
  const [pageNumbers1, setPageNumbers1] = useState([]);
  // for assigned Data
  const [currentPage2, setCurrentPage2] = useState(1);
  // const [recordsPerPage2, setRecordsPerPage2] = useState(10);
  const recordsPerPage = 10;
  const [pageNumbers2, setPageNumbers2] = useState([]);

   // State to track the progress
   const fileInputRef = useRef(null); // Ref for file input
   const [progress, setProgress] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const totalPages1 = Math.ceil(unassignedData.length / itemsPerPage1);
    const pages1 = [];
    for (let i = 1; i <= totalPages1; i++) {
      pages1.push(i);
    }
    setPageNumbers1(pages1);
  }, [unassignedData]);

  // useEffect(() => {
  //   const totalPages2 = Math.ceil(currentItems2.length / itemsPerPage2);
  //   const pages2 = [];
  //   for (let i = 1; i <= totalPages2; i++) {
  //     pages2.push(i);
  //   }
  //   setPageNumbers2(pages2);
  // }, [showAssigned]);

  // Arbitrator Fetch Code
  useEffect(() => {
    const fetchArbitrators = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/arbitrator`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedArbitrators = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedArbitrators);
        setArbitrators(parsedArbitrators);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchArbitrators();
  }, []);
  console.log(arbitrators);

  // unassignedLots Fetch Code
  useEffect(() => {
    const fetchUnassignedLots = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/unassignLots`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedUnassignedLots = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedUnassignedLots);
        setUnassignedLots(parsedUnassignedLots);
        setGetData(true);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUnassignedLots();
  }, []);

  console.log(unassignedLots);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setFilteredDataForSelect(data);
  //   }
  // }, [data]); // Dependency array includes isActive

  // Set headers dynamically based on the first item in the data
  useEffect(() => {
    if (data.length > 0) {
      setHeaders(Object.keys(data[0])); // Get keys from the first object
    }
  }, [data]);

  // Handles the Arbitrator Change
  const handleArbitratorChange = (e) => {
    const value = e.target.value;
    setSelectedArbitrator(value);
    if (value === "all" || value === "") {
      setFilteredDataForSelect(data); // Show all data
    } else {
      setFilteredDataForSelect(
        data.filter((record) => record.assignedArbitrator === value)
      );
    }
    setCurrentPage(1);
  };
  // console.log(selectedArbitrator);
  // console.log(filteredDataForSelect);

  // Loading Spinner Compenent
  if (loading) return <LoadingSpinner />;

  // This handles the unassigned Lotd Starts Here

  const handleGetUnassignedLots = async (lot) => {
    console.log(lot);
    // setUnassignedID(lot);
    // setIspending(true)
    setLoading(true); // Start loading
    try {
      // setLoading(true); // Start loading
      // setIspending(true)
      const response = await fetch(
        // `http://88.135.73.195/api/Uploaddata?Lot_no=${lot}`
        `${API_BASE_URL}/api/Uploaddata?Lot_no=${lot}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const parsedUnassignedData = JSON.parse(result); // Ensure parsedArbitrators is an array
      console.log(parsedUnassignedData);
      setUnassignedData(parsedUnassignedData);
      setCurrentLotId(lot); // Set the current lot ID
      setIsDataFetched(true); // Update the state to indicate data has been fetched
      setShowData(true);
      toast.success("Data Received Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 1000,
      });
      setGetData(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  console.log(unassignedData);
  console.log(currentLotId);

  // logic for pagination with unassigned Data starts here

  const indexOfLastItem1 = currentPage1 * itemsPerPage1;
  const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
  const updatedData = unassignedData.map((item, index) => {
    const { Case_id, UPLODED_DATE, ...rest } = item;
    return {
      ...rest,
    };
  });
  const currentItems1 = updatedData.slice(indexOfFirstItem1, indexOfLastItem1);

  // logic for pagination with unassigned Data ends here

  // This handles the unassigned Lotd Ends Here

  //  This handles the Assign Arbitrator Logic Starts Here

  const handleAssignArbitrator = () => {
    if (arbitrators.length === 0) {
      toast.error("No arbitrators available for assignment", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 1000,
      });
      return;
    }
  
    // Shuffle arbitrators array to ensure randomness
    const shuffledArbitrators = [...arbitrators].sort(() => Math.random() - 0.5);
  
    const totalLots = unassignedData.length;
    const totalArbitrators = shuffledArbitrators.length;
    const lotsPerArbitrator = Math.floor(totalLots / totalArbitrators);
    const remainingLots = totalLots % totalArbitrators;
  
    // Create an array of arbitrators repeated 'lotsPerArbitrator' times
    let arbitratorPool = shuffledArbitrators.flatMap(arbitrator =>
      Array(lotsPerArbitrator).fill(arbitrator)
    );
  
    // Randomly assign the remaining lots
    for (let i = 0; i < remainingLots; i++) {
      const randomArbitrator = shuffledArbitrators[Math.floor(Math.random() * totalArbitrators)];
      arbitratorPool.push(randomArbitrator);
    }
  
    // Shuffle the arbitratorPool to randomize assignments
    arbitratorPool = arbitratorPool.sort(() => Math.random() - 0.5);
  
    // Object to track assignment counts
    const assignmentCounts = shuffledArbitrators.reduce((acc, arbitrator) => {
      acc[arbitrator.Arb_name] = 0;
      return acc;
    }, {});
  
    // Assign arbitrators randomly but equally distributed to the data
    const dataWithAssignments = unassignedData.map((item, index) => {
      const arbitrator = arbitratorPool[index]; // Randomized but equally distributed arbitrator
      
      assignmentCounts[arbitrator.Arb_name] += 1;
  
      return {
        assignedArbitrator: arbitrator.Arb_name,
        assignedArbitratorId: arbitrator.Arb_id,
        ...item,
      };
    });
  
    console.log("Data with Assignments:", dataWithAssignments);
    console.log("Assignment Counts:", assignmentCounts);
  
    const updatedData = dataWithAssignments.map((item) => {
      const { SR_No, Case_id, UPLODED_DATE,assignedArbitratorId,LOT_NO,ACC_NO,CUST_ID, ...rest } = item;
      return {
        ...rest,
      };
    });
  
    setData(updatedData);
    setFilteredDataForSelect(updatedData);
    setAssignedData(dataWithAssignments);
    
    toast.success("Arbitrator Assigned Successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
      autoClose: 1000,
    });
    setShowAssigned(true);
    setShowTable(true);
    setIsDisabled(true);
  };
  
  
  
  
  console.log(data);
  //  This handles the Assign Arbitrator Logic Ends Here

  //  Pagination Logic for assigned Data Starts Here

  const handleShowAll = () => {
    setShowAssigned(false); // Show all data
    setShowTable(true); // Show the table
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const updatedAssignedData = assignedData.map((item) => {
    const { SR_No, assignedArbitrator, ...rest } = item;
    return {
      SR_No: SR_No,
      Assigned_Arbitrator: assignedArbitrator,
      ...rest,
    };
  });

  // const handleTransfer = async () => {
  //   // Function to format the data
  //   const formattedData = assignedData.map((item) => ({
  //     Client_id: item.Client_id,
  //     Product_id: item.product_id,
  //     Case_id: item.Case_id,
  //     Arbitrator_id: item.assignedArbitratorId,
  //     Assign_date: new Date(item.UPLODED_DATE).toLocaleDateString("en-US"), // Formats date as DD/MM/YYYY
  //     // Assign_date: new Date(item.UPLODED_DATE).toLocaleDateString("en-GB"), // Formats date as DD/MM/YYYY
  //     Assign_by: "Resolution", // Assuming this is the same for all
  //   }));
  //   console.log(formattedData);

  //   // Log the final JSON object to the console
  //   console.log(
  //     "Final JSON object:",
  //     JSON.stringify({ case: formattedData }, null, 2)
  //   );

  //   try {
  //     setLoading(true);
  //     const response = await fetch(`${API_BASE_URL}/api/assignArb`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ case: formattedData }),
  //     });

  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       throw new Error(
  //         `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
  //       );
  //     }
  //     const result = await response.json(); // Process the response
  //     console.log("Upload response:", result);
  //     setClearForm(true);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error uploading data:", error);
  //     alert(`Error uploading data: ${error.message}`);
  //   }
  // };
  const handleTransfer = async () => {
    // Function to format the data
    const formattedData = assignedData.map((item) => ({
      Client_id: item.Client_id,
      Product_id: item.product_id,
      Case_id: item.Case_id,
      Arbitrator_id: item.assignedArbitratorId,
      Assign_date: new Date(item.UPLODED_DATE).toLocaleDateString("en-US"), // Formats date as MM/DD/YYYY
      Assign_by: "Resolution", // Assuming this is the same for all
    }));

    console.log(formattedData);

    // Log the final JSON object to the console
    console.log(
      "Final JSON object:",
      JSON.stringify({ case: formattedData }, null, 2)
    );

    // Start loading indicator and progress
    setLoading1(true);
    setTotalRecords(formattedData.length); // Set total records for progress bar
    setProgress(0); // Initialize progress to 0

    try {
      // Map through each row and upload data sequentially
      await formattedData.reduce(async (previousPromise, row, index) => {
        // Wait for the previous promise to resolve
        await previousPromise;

        console.log(`Uploading record ${index + 1} of ${formattedData.length}`);

        // Make the API call for the current row
        const response = await fetch(`${API_BASE_URL}/api/assignArb`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case: [row] }), // Send data as an array of one item
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
          );
        }

        // Process the response
        await response.json();

        // Update the progress after each upload
        setProgress((prevProgress) => prevProgress + 1);

        // Log progress to the console
        console.log(
          `Progress: ${index + 1}/${formattedData.length} records uploaded`
        );

        // Optional: Small delay to show progress smoothly
        await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if needed
      }, Promise.resolve()); // Start with an immediately resolving promise

      // Show success toast message
      toast.success("Data Uploaded Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 1000,
      });

      // Reset form and inputs
      setClearForm(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear file input
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading1(false); // Stop loading indicator when all tasks complete
    }
  };

  // const cleanData = (data) => {
  //   const cleanDate = (dateString) => {
  //     return dateString.split("T")[0];
  //   };

  //   const cleanField = (field) => {
  //     return field.replace(/[\u001e]/g, "");
  //   };

  //   return data.map((item) => {
  //     let newItem = {};
  //     for (let key in item) {
  //       let value = item[key];
  //       if (typeof value === "string") {
  //         // Clean date strings and remove special characters
  //         if (value.includes("T00:00:00")) {
  //           value = cleanDate(value);
  //         }
  //         value = cleanField(value);
  //       }
  //       newItem[key] = value;
  //     }
  //     return newItem;
  //   });
  // };

  // const cleanedItems = cleanData(currentItems2);
  // console.log(cleanedItems);

  const handleunassinedlots = () => {
    setShowUnassigned(true);
  };

  // Pagination logic
  // Calculate total pages based on the data length
  const totalPages = Math.ceil(filteredDataForSelect.length / recordsPerPage);
  const maxPagesToShow = 5;

  // Calculate page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Determine the range of pages to display
  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxPagesToShow / 2),
      totalPages - maxPagesToShow + 1
    )
  );
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  const displayedPages = pageNumbers.slice(startPage - 1, endPage);

  // Handle page change
  const handlePageClick = (number) => {
    setCurrentPage(number);
  };

  // Handle previous and next page clicks
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the data to be displayed on the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredDataForSelect.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  return (
    <>
      {!unassignedLots.length > 0 && <p>No unassignedLot available</p>}
      {/* To show the list of unassigne lots name only */}
      {!showData && !clearForm && !showTable && unassignedLots.length > 0 && (
        <div className="row table-container mt-5">
          {/* {showUnassigned && !clearForm && !showTable && ( */}
          <>
            <div className="col-md-12 mx-auto table-wrapper">
              <table className="responsive-table">
                <thead className="text-center">
                  <tr className="table-info">
                    <th scope="col" className="text-center">
                      Sr No
                    </th>
                    <th scope="col" className="text-center">
                      Lots
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {unassignedLots.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{item.Lots}</td>
                      <td className="text-center">
                        <button
                          onClick={() => handleGetUnassignedLots(item.Lots)}
                          variant="primary"
                          disabled={isPending}
                          className="custBtn"
                        >
                          Show
                        </button>

                        {isDataFetched && currentLotId === item.Lots && (
                          <button
                            onClick={() => handleAssignArbitrator(item.Lots)}
                            variant="success"
                            className="custBtn"
                          >
                            Assign Arbitrator
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        </div>
      )}

      {showData && !showAssigned && (
        <div className="container">
          <div className="row align-items-center my-2">
            <div className="col-md-6">
              <h4 className="my-3 ms-1">Assign Arbitrator</h4>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-2">
              <button
                className="custBtn "
                onClick={() => handleAssignArbitrator(currentLotId)}
              >
                Assign
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ReusableTable
                data={currentItems1}
                currentPage={currentPage1}
                pageNumbers={pageNumbers1}
                setCurrentPage={setCurrentPage1}
              />
            </div>
          </div>
        </div>
      )}

      {showAssigned && !clearForm && !loading1 && (
        <div className="row my-2 ms-5">
          <div className="col-md-4">
            <Form.Select
              aria-label="Default select example"
              onChange={handleArbitratorChange}
              className="custom_input"
            >
              <option value="">All</option>
              {arbitrators.map((item) => (
                <option key={item.Arb_id} value={item.Arb_name}>
                  {item.Arb_name}
                </option>
              ))}
            </Form.Select>
            {/* <Form.Select
              aria-label="Default select example"
              onChange={handleArbitratorChange}
              className="custom_input"
            >
              <option value="" selected>
                All
              </option>
              {arbitrators.map((item) => (
                <option key={item.Arb_id} value={item.Arb_name}>
                  {item.Arb_name}
                </option>
              ))}
            </Form.Select> */}
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-2">
            <button className="custBtn" onClick={handleTransfer}>
              Save Data
            </button>
          </div>
        </div>
      )}

      {data && showTable && !clearForm && !loading1 && (
        // <ReusableTableWithFilter
        //   data={filteredData}
        //   headers={headers}
        //   filterOptions={arbitrators.map((item) => item.Arb_name)}
        //   onFilterChange={handleArbitratorChange}
        // />
        // <table className="table table-striped">
        //   <thead>
        //     <tr>
        //       {headers.map((header, index) => (
        //         <th key={index}>{header}</th>
        //       ))}
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {filteredDataForSelect.length > 0 ? (
        //       filteredDataForSelect.map((row, rowIndex) => (
        //         <tr key={rowIndex}>
        //           {headers.map((header, colIndex) => {
        //             const cellValue = row[header];
        //             return <td key={colIndex}>{cellValue}</td>;
        //           })}
        //         </tr>
        //       ))
        //     ) : (
        //       <tr>
        //         <td colSpan={headers.length}>No data available</td>
        //       </tr>
        //     )}
        //   </tbody>
        // </table>
        <div className="table-container">
          <div className="table-wrapper">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Serial No.</th>
                  {headers.map((header) => (
                    <th key={header} className="text-center">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        maxHeight: "50px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="text-center custom_fz"
                    >
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {indexOfFirstRecord + index + 1}
                      </td>
                      {headers.map((header) => (
                        <td
                          key={header}
                          style={{
                            textAlign:
                              header === "CUST_NAME" ||
                              header === "assignedArbitrator"
                                ? "left"
                                : "center",
                          }}
                        >
                          {item[header]}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={headers.length}>No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination className="justify-content-center">
              {/* Left Arrow */}
              <Pagination.Prev
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </Pagination.Prev>

              {/* Dynamic range of page numbers */}
              {displayedPages.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => handlePageClick(number)}
                >
                  {number}
                </Pagination.Item>
              ))}

              {/* Right Arrow */}
              <Pagination.Next
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </Pagination.Next>
            </Pagination>
          )}
        </div>
      )}

      {loading1 && (
        <div className="progress-container">
          <p className="progress-text">
            {progress}/{totalRecords} records uploaded
          </p>
          <div className="progress-bar-background mt-3">
            <div
              className="progress-bar"
              style={{
                width: `${(progress / totalRecords) * 100}%`,
              }}
            >
              {Math.round((progress / totalRecords) * 100)}%
            </div>
          </div>
        </div>
      )}

      {clearForm && (
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            {/* <Refresh
              message="Arbitrator Assigned Successfully"
              redirectPath="/instdashboard" // Specify the redirect path
            /> */}
            <ClearForm
              message="Arbitrator Assigned Successfully!!!!"
              redirectPath="/instdashboard"
            />
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default AssignArbitrator;
